@charset "UTF-8";
@import url(~ress/dist/ress.min.css);
@import url(~swiper/dist/css/swiper.min.css);
@import url(~remodal/dist/remodal.css);
@import url(~remodal/dist/remodal-default-theme.css);
@import url(~baguettebox.js/dist/baguettebox.min.css);
html {
  font-size: 16px; }

body {
  min-width: 70rem;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Yu Gothic", YuGothic, Verdana, Meiryo, "M+ 1p", sans-serif;
  color: #333; }
  @media (max-width: 768px) {
    body {
      overflow: hidden;
      width: 100%;
      min-width: auto; } }

a {
  color: inherit;
  text-decoration: none; }

*:focus {
  outline: none; }

#container {
  width: 70rem;
  margin: 0 auto 5rem; }
  @media (max-width: 768px) {
    #container {
      width: calc(100% - 3rem);
      margin-bottom: 2rem; } }

#footer {
  position: relative;
  width: 100%;
  padding: 2rem 0;
  background: url(/assets/image/common/bg-red.jpg) repeat center; }
  @media (max-width: 1024px) {
    #footer {
      padding: 3rem 0; } }

#header {
  width: 100%; }

.c-btn {
  display: block;
  height: 3rem;
  color: #D4000E;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  line-height: 3rem;
  letter-spacing: .25rem;
  background: url(/assets/image/common/bg.jpg) repeat;
  transition: .3s; }
  @media (max-width: 768px) {
    .c-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      padding: .5rem 1rem;
      font-size: 1.25rem;
      line-height: normal;
      box-sizing: border-box; } }
  .c-btn:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
  .c-btn.--red {
    color: #fff;
    background-image: url(/assets/image/common/bg-red.jpg); }

.c-heading {
  padding: 2rem 0;
  text-align: center;
  background: #fff;
  border-bottom: 0.5rem solid #D4000E; }
  .c-heading__main {
    margin-bottom: .5rem;
    color: #D4000E;
    font-size: 3rem; }
    @media (max-width: 768px) {
      .c-heading__main {
        font-size: 2rem; } }
  .c-heading__sub {
    font-size: 1.5rem; }
    @media (max-width: 768px) {
      .c-heading__sub {
        font-size: 1.25rem; } }

.c-section__body {
  padding: 4rem 0 8rem;
  background: url(/assets/image/common/bg.jpg) repeat center; }
  @media (max-width: 768px) {
    .c-section__body {
      padding: 3rem 0 6rem; } }

.c-section__inner {
  position: relative;
  width: 70rem;
  margin: auto; }
  @media (max-width: 768px) {
    .c-section__inner {
      width: calc(100% - 3rem); } }

.c-section__inner.--min {
  width: 45rem; }
  @media (max-width: 768px) {
    .c-section__inner.--min {
      width: calc(100% - 3rem); } }

.c-section-heading {
  padding: 2rem 0;
  text-align: center;
  background: #fff;
  -o-border-image: url("/assets/image/common/bg-red.jpg") 0 0 8 0 round;
     border-image: url("/assets/image/common/bg-red.jpg") 0 0 8 0 round;
  border-style: solid;
  border-width: 0 0 .5rem 0; }
  .c-section-heading__main {
    margin-bottom: .5rem;
    color: #D4000E;
    font-size: 3rem; }
    @media (max-width: 768px) {
      .c-section-heading__main {
        font-size: 2rem; } }
  .c-section-heading__sub {
    font-size: 1.5rem; }
    @media (max-width: 768px) {
      .c-section-heading__sub {
        font-size: 1.25rem; } }

.c-zoom {
  position: relative;
  display: block; }
  .c-zoom img {
    -webkit-filter: brightness(1);
            filter: brightness(1);
    transition: .3s; }
  .c-zoom img:hover {
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5); }
  .c-zoom::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 3rem;
    height: 3rem;
    background: url(/assets/image/common/zoom.svg) no-repeat center/cover;
    opacity: 0;
    transition: .3s;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    pointer-events: none; }
  .c-zoom:hover::before {
    opacity: 1; }

.p-404 {
  width: 45rem;
  margin: auto;
  background: none; }
  @media (max-width: 768px) {
    .p-404 {
      width: calc(100% - 3rem); } }
  .p-404__message {
    margin-bottom: 2rem;
    text-align: center;
    line-height: 1.7; }
  .p-404__btn {
    display: block;
    width: 20rem;
    height: 3rem;
    margin: auto;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    line-height: 3rem;
    letter-spacing: .25rem;
    background: url(/assets/image/common/bg-red.jpg) repeat;
    transition: .3s; }
    @media (max-width: 768px) {
      .p-404__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        padding: .5rem 1rem;
        font-size: 1.25rem;
        line-height: normal;
        box-sizing: border-box; } }
  .p-404__btn:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }

.p-advantage__block {
  display: flex; }
  @media (max-width: 768px) {
    .p-advantage__block {
      display: block; } }

.p-advantage__block + .p-advantage__block {
  margin-top: 3rem; }
  @media (max-width: 768px) {
    .p-advantage__block + .p-advantage__block {
      margin-top: 2rem; } }

.p-advantage__heading {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 20rem;
  margin-right: 4rem;
  color: #D4000E;
  font-size: 1.75rem;
  font-weight: bold; }
  @media (max-width: 768px) {
    .p-advantage__heading {
      justify-content: flex-start;
      width: 100%;
      margin: 0 0 1rem;
      font-size: 1.5rem; } }

.p-advantage__heading::after {
  content: url(/assets/image/advantage/arrow.svg); }
  @media (max-width: 768px) {
    .p-advantage__heading::after {
      display: none; } }

.p-advantage__content {
  flex: 1; }

.p-advantage__content > p {
  line-height: 1.7; }

.p-advantage__add-content {
  margin-top: 1rem; }

.p-advantage__list {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 2rem);
  margin: -.5rem -1rem;
  list-style: none; }

.p-advantage__list > li {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(50% - 2rem);
  margin: .5rem 1rem;
  padding: .5rem 1rem;
  color: #D4000E;
  text-align: center;
  background: #fff; }

.p-footer {
  width: 70rem;
  margin: auto; }
  @media (max-width: 768px) {
    .p-footer {
      width: 100%; } }
  .p-footer__pagetop {
    position: absolute;
    left: 50%;
    bottom: calc(100% + 2rem);
    font-size: 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .p-footer__pagetop::before {
    content: "";
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    background: url(/assets/image/footer/pagetop.svg) no-repeat center/cover; }

.p-footer-offices {
  margin-bottom: 3rem;
  color: #fff; }
  @media (max-width: 768px) {
    .p-footer-offices {
      margin: 0 1.5rem 2rem; } }

.p-footer-offices__heading {
  margin-bottom: .25rem;
  font-family: A1ゴシック M, sans-serif;
  font-size: 1.5rem;
  text-align: center; }
  @media (max-width: 768px) {
    .p-footer-offices__heading {
      font-size: 1.25rem;
      text-align: left; } }

.p-footer-offices__supplement {
  display: table;
  margin: 0 auto 2rem;
  font-size: .875rem; }
  @media (max-width: 768px) {
    .p-footer-offices__supplement {
      display: block; } }

.p-footer-offices__boxies {
  display: flex; }
  @media (max-width: 768px) {
    .p-footer-offices__boxies {
      display: block; } }

.p-footer-offices__box {
  flex: 1;
  line-height: 1.5;
  -webkit-font-feature-settings: "palt";
          font-feature-settings: "palt"; }
  .p-footer-offices__box + .p-footer-offices__box {
    margin-left: 2rem;
    padding-left: 2rem;
    border-left: 1px solid #fff; }
    @media (max-width: 768px) {
      .p-footer-offices__box + .p-footer-offices__box {
        margin: 1rem 0 0;
        padding: 1rem 0 0;
        border-left: none;
        border-top: 1px solid #fff; } }

.p-footer-offices__main-office {
  font-size: 1.125rem; }

.p-footer__small {
  display: block;
  width: 100%;
  color: #fff;
  text-align: center; }
  @media (max-width: 768px) {
    .p-footer__small {
      width: auto;
      margin: 0 1.5rem;
      text-align: left; } }

.p-header {
  position: relative; }

.p-header__slide {
  padding-bottom: 1rem;
  background: url(/assets/image/common/bg-red.jpg) repeat center; }
  @media (max-width: 768px) {
    .p-header__slide {
      padding-bottom: .5rem; } }
  .p-header__slide img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 100%; }
  .p-header__slide .swiper-slide {
    max-width: 20%; }
    @media (max-width: 768px) {
      .p-header__slide .swiper-slide {
        max-width: 40%; } }

.p-header__logo {
  width: 30rem;
  opacity: 0;
  transition: 1s ease;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }
  @media (max-width: 1024px) {
    .p-header__logo {
      width: calc(42% - 1.5rem);
      bottom: auto; } }
  @media (max-width: 768px) {
    .p-header__logo {
      width: 60%;
      margin: 0 0 2rem auto; } }
  .p-header__logo.is-animated {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  @media (max-width: 768px) {
    .p-header__logo img {
      width: 100%; } }

.p-header__mark {
  position: absolute;
  left: 0;
  top: 9.5vw;
  z-index: 1; }
  @media (max-width: 768px) {
    .p-header__mark {
      top: 23vw; } }
  .p-header__mark > svg {
    transform-box: fill-box; }

.p-header-catchcopy {
  position: absolute;
  right: 4rem;
  top: 2rem;
  width: 15rem;
  color: #000;
  opacity: 0;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
  transition: 1s ease 1s; }
  @media (max-width: 768px) {
    .p-header-catchcopy {
      position: static;
      margin: auto;
      -webkit-transform: none;
              transform: none;
      width: 60vw;
      max-width: 20rem; } }
  .p-header-catchcopy.is-animated {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .p-header-catchcopy > img {
    width: 100%; }
  .p-header-catchcopy__first-brackets {
    margin-top: -.5em; }
  .p-header-catchcopy__reader {
    -webkit-transform: translateX(0.3em);
            transform: translateX(0.3em); }

.p-header-block.--first {
  position: relative;
  padding: 5rem 0 1rem calc(440px + 3rem);
  background: url(/assets/image/common/bg.jpg) repeat center; }
  @media (max-width: 768px) {
    .p-header-block.--first {
      padding: 2rem 1.5rem; } }

.p-header-block.--second {
  min-height: 14rem;
  padding: 2rem 0 3rem calc(440px + 3rem); }
  @media (max-width: 768px) {
    .p-header-block.--second {
      min-height: auto;
      padding: 2rem 1.5rem; } }

.p-header-news {
  display: inline-block;
  vertical-align: bottom;
  margin: -1rem;
  color: #D4000E;
  border-collapse: separate;
  border-spacing: 1rem; }
  .p-header-news__head {
    margin-bottom: 1rem;
    color: #D4000E; }
    @media (max-width: 768px) {
      .p-header-news__head {
        text-align: center; } }
  .p-header-news__time {
    font-weight: normal;
    text-align: left;
    vertical-align: top; }
  .p-header-news__title a {
    text-decoration: underline; }
  .p-header-news__more {
    display: inline-block;
    vertical-align: bottom;
    margin-left: 2rem;
    color: #D4000E;
    text-decoration: underline; }
    @media (max-width: 768px) {
      .p-header-news__more {
        display: table;
        margin: 2rem auto 0; } }
  .p-header-news__more::before {
    content: '> '; }

.p-header-nav {
  background: #231815; }
  @media (max-width: 768px) {
    .p-header-nav > input {
      position: absolute;
      visibility: hidden;
      opacity: 0; } }
  @media (max-width: 768px) {
    .p-header-nav__toggle {
      display: block;
      width: 100%;
      padding: 0 1.5rem;
      height: 3rem;
      color: #fff;
      font-size: 1.5rem;
      font-weight: bold;
      text-align: center;
      line-height: 3rem;
      background: #231815; } }
  .p-header-nav__toggle::before {
    content: "MENU"; }
  @media (max-width: 768px) {
    .p-header-nav > input:checked + .p-header-nav__toggle::before {
      content: "CLOSE"; } }
  .p-header-nav__link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70rem;
    height: 3rem;
    margin: auto;
    color: #fff;
    line-height: 3rem;
    list-style: none; }
    @media (max-width: 768px) {
      .p-header-nav__link {
        overflow: hidden;
        display: block;
        width: 100%;
        height: auto;
        max-height: 0;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        transition: .3s ease-in-out; } }
  @media (max-width: 768px) {
    .p-header-nav > input:checked ~ .p-header-nav__link {
      max-height: 15rem; } }
  .p-header-nav__link > li {
    display: flex;
    height: 100%;
    align-items: center; }
  @media (max-width: 768px) {
    .p-header-nav__link > li + li {
      border-top: 1px solid rgba(255, 255, 255, 0.2); } }
  .p-header-nav__link > li + li::before {
    content: "";
    width: 1px;
    height: 50%;
    margin: 0 1.5rem;
    background-color: rgba(255, 255, 255, 0.4); }
    @media (max-width: 768px) {
      .p-header-nav__link > li + li::before {
        display: none; } }
  .p-header-nav__link a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    line-height: 3rem; }
    @media (max-width: 768px) {
      .p-header-nav__link a {
        padding: 0 1.5rem; } }
  @media (max-width: 768px) {
    .p-header-nav__link a::after {
      content: "";
      width: 1em;
      height: 1rem;
      background: url(/assets/image/common/arrow-right-white.svg) no-repeat center/100%;
      opacity: .7; } }
  .p-header-nav__link a:hover {
    text-decoration: underline; }

.p-news {
  width: 45rem;
  margin: auto;
  background: none; }
  @media (max-width: 768px) {
    .p-news {
      width: calc(100% - 3rem);
      padding-bottom: 8rem; } }
  .p-news__noposts {
    text-align: center; }

.p-news-post + .p-news-post {
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 5px dotted #D4000E; }

.p-news-post:last-child {
  margin-bottom: -1.5rem; }

.p-news-post + .p-news-paginate {
  margin-top: 3rem; }

.p-news-post__header {
  margin-bottom: 2rem; }

.p-news-post__title {
  margin-bottom: 1rem;
  color: #D4000E;
  font-size: 1.75rem;
  font-weight: bold; }

.p-news-post__date {
  color: rgba(0, 0, 0, 0.4); }

.p-news-post__body {
  line-height: 1.7;
  text-align: justify; }

.p-news-post__body a {
  color: #D4000E;
  text-decoration: underline; }

.p-news-post__body p {
  overflow: hidden;
  margin: 2rem 0; }

.p-news-post__body img.alignnone,
.p-news-post__body img.aligncenter {
  display: block;
  margin: auto; }

.p-news-post__body img.alignleft {
  float: left;
  margin-right: 1rem; }

.p-news-post__body img.alignright {
  float: right;
  margin-left: 1rem; }

.p-news-post__body ul,
.p-news-post__body ol {
  padding-left: 1.5rem; }

.p-news-paginate {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
  color: #D4000E;
  border-top: 5px dotted #D4000E; }
  @media (max-width: 768px) {
    .p-news-paginate {
      display: -ms-grid;
      display: grid;
      -ms-grid-rows: auto auto;
      -ms-grid-columns: 50% 50%;
          grid-template: "npPrev npNext" auto "npHome npHome" auto/ 50% 50%; } }
  .p-news-paginate a {
    text-decoration: underline; }
  .p-news-paginate__home {
    margin: 0 1rem; }
    @media (max-width: 768px) {
      .p-news-paginate__home {
        grid-area: npHome;
        text-align: center;
        margin: 1rem 0 0; } }
  @media (max-width: 768px) {
    .p-news-paginate__prev {
      grid-area: npPrev;
      justify-self: flex-end;
      margin-right: .5rem; } }
  @media (max-width: 768px) {
    .p-news-paginate__next {
      grid-area: npNext;
      margin-left: .5rem; } }
  @media (max-width: 768px) {
    .p-news-paginate__home {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2; }
    .p-news-paginate__prev {
    -ms-grid-row: 1;
    -ms-grid-column: 1; }
    .p-news-paginate__next {
    -ms-grid-row: 1;
    -ms-grid-column: 2; } }
  .p-news-paginate__prev.--disabled, .p-news-paginate__next.--disabled {
    opacity: .3;
    pointer-events: none; }

.p-roadmap__caption {
  margin-bottom: 2rem;
  line-height: 1.7; }

@media (max-width: 414px) {
  .p-roadmap__map {
    width: 100%;
    max-width: 12rem;
    margin: auto; } }

.p-roadmap__map img {
  max-width: 100%; }

.p-standard__items {
  display: flex;
  margin-bottom: 3rem; }
  @media (max-width: 768px) {
    .p-standard__items {
      display: block; } }

.p-standard__col {
  flex: 1; }

.p-standard__col + .p-standard__col {
  margin-left: 5rem; }
  @media (max-width: 768px) {
    .p-standard__col + .p-standard__col {
      margin: 2rem 0 0; } }

.p-standard__list + .p-standard__heading {
  margin-top: 2rem; }

.p-standard__heading {
  margin-bottom: 1rem;
  padding: .5rem 1rem;
  color: #D4000E;
  font-size: 1.25rem;
  border: 2px solid #D4000E; }

.p-standard__list {
  margin-left: 1.25rem; }

.p-standard__list > li + li {
  margin-top: .5rem; }

.p-standard-gallery__heading {
  margin-bottom: 2rem;
  font-size: 1.5rem;
  text-align: center; }

.p-standard-gallery__inner {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 1rem);
  margin: -.5rem; }

.p-standard-gallery__photo {
  width: calc(20% - 1rem);
  margin: .5rem; }
  @media (max-width: 768px) {
    .p-standard-gallery__photo {
      width: calc(50% - 1rem); } }

.p-standard-gallery__photo img {
  width: 100%; }

.p-whatis-area__caption {
  width: 34rem;
  margin-bottom: 3rem;
  line-height: 1.7; }
  @media (max-width: 768px) {
    .p-whatis-area__caption {
      width: 100%;
      margin-bottom: 2rem; } }

.p-whatis-area__mark {
  position: absolute;
  right: 0;
  top: -5.5rem; }
  @media (max-width: 768px) {
    .p-whatis-area__mark {
      position: static;
      margin-bottom: 2rem;
      padding: 1rem;
      text-align: center;
      border-radius: .5rem;
      background: #fff; } }

.p-whatis-area__locations {
  display: flex;
  margin-bottom: 4rem; }
  @media (max-width: 768px) {
    .p-whatis-area__locations {
      flex-wrap: wrap;
      margin: -.5rem -.5rem 1.5rem; } }

@media (max-width: 768px) {
  .p-whatis-area__locations > figure {
    width: calc(50% - 1rem);
    margin: .5rem !important; } }

.p-whatis-area__btn {
  width: 32rem;
  margin: auto; }
  @media (max-width: 768px) {
    .p-whatis-area__btn {
      width: 100%; } }

.p-whatis-area-location {
  text-align: center; }
  .p-whatis-area-location + .p-whatis-area-location {
    margin-left: 2rem; }
  .p-whatis-area-location > img {
    width: 100%; }
  .p-whatis-area-location__name {
    margin-top: .5rem;
    color: #D4000E;
    font-size: 1.25rem; }
    @media (max-width: 768px) {
      .p-whatis-area-location__name {
        margin-top: 0;
        font-size: 1rem; } }

.p-whatis {
  padding: 0; }
  .p-whatis__heading {
    padding: 2rem;
    background: url(/assets/image/common/bg-red.jpg) repeat;
    color: #fff;
    font-size: 2rem;
    text-align: center; }
    @media (max-width: 768px) {
      .p-whatis__heading {
        font-size: 1.5rem; } }

.p-whatis-slide::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 32rem;
  background: #fff; }
  @media (max-width: 768px) {
    .p-whatis-slide::before {
      height: 81%; } }

.p-whatis-slide__item {
  overflow: hidden; }

@media (max-width: 768px) {
  .p-whatis-slide__prev, .p-whatis-slide__next {
    top: 1rem;
    margin-top: 0;
    background-size: 2.5rem !important; } }

.p-whatis-point__inner.--primary {
  padding-top: 30rem; }
  @media (max-width: 768px) {
    .p-whatis-point__inner.--primary {
      display: flex;
      flex-direction: column;
      padding-top: 0; } }

.p-whatis-point__inner.--secondray {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.p-whatis-point__sentence {
  position: absolute;
  left: 0;
  top: 4rem;
  width: 28rem; }
  @media (max-width: 768px) {
    .p-whatis-point__sentence {
      position: relative;
      top: 0;
      order: 3;
      width: 100%;
      margin-bottom: 2rem; } }

.p-whatis-point__sentence::before {
  position: absolute;
  left: 80%;
  top: 110%; }
  @media (max-width: 768px) {
    .p-whatis-point__sentence::before {
      left: auto;
      right: 0;
      top: auto;
      bottom: 0;
      z-index: -1;
      opacity: .1; } }

.p-whatis-point__heading {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: #231815;
  font-size: 1.75rem; }
  @media (max-width: 768px) {
    .p-whatis-point__heading {
      align-items: flex-start;
      font-size: 1.5rem; } }

@media (max-width: 768px) {
  .p-whatis-point__heading > br {
    display: none; } }

.p-whatis-point__heading::before {
  content: "";
  width: 5rem;
  height: 5rem;
  margin-right: 1rem;
  background: url(/assets/image/food-tourism/point.svg) no-repeat center/contain; }
  @media (max-width: 768px) {
    .p-whatis-point__heading::before {
      min-width: 3rem;
      height: 3rem; } }

.p-whatis-point__caption {
  line-height: 1.7;
  text-align: justify; }

.p-whatis-point__image {
  position: absolute;
  top: 4rem;
  left: calc(28rem + 3rem); }
  @media (max-width: 768px) {
    .p-whatis-point__image {
      position: static;
      order: 1;
      width: calc(100% + 1.5rem); } }

@media (max-width: 768px) {
  .p-whatis-point__image > img {
    width: 100%; } }

.p-whatis-point__some {
  position: relative;
  padding-bottom: 4rem; }
  @media (max-width: 768px) {
    .p-whatis-point__some {
      padding-bottom: 2rem; } }

.p-whatis-point__catch {
  display: flex;
  margin-top: -4rem; }
  @media (max-width: 768px) {
    .p-whatis-point__catch {
      display: block;
      order: 2;
      z-index: 1;
      height: 25vw;
      max-height: 10rem;
      margin-bottom: 2rem;
      font-size: 0; } }

.p-whatis-point__catch img {
  width: 100%; }
  @media (max-width: 768px) {
    .p-whatis-point__catch img {
      width: auto;
      height: 100%; } }

.p-whatis-point__experts, .p-whatis-point__gallery {
  display: flex;
  list-style: none; }
  @media (max-width: 768px) {
    .p-whatis-point__experts, .p-whatis-point__gallery {
      overflow-x: scroll;
      margin: 0 -1.5rem; } }

@media (max-width: 768px) {
  .p-whatis-point__experts::before, .p-whatis-point__gallery::before {
    content: "";
    min-width: 1.5rem; } }

@media (max-width: 768px) {
  .p-whatis-point__experts::after, .p-whatis-point__gallery::after {
    content: "";
    min-width: 1.5rem; } }

.p-whatis-point__experts > li + li,
.p-whatis-point__gallery > li + li {
  margin-left: 2rem; }

.p-whatis-point.--first .p-whatis-point__sentence::before {
  content: url(/assets/image/food-tourism/content01/icon.svg); }

.p-whatis-point.--first .p-whatis-point__catch {
  flex: 1; }

.p-whatis-point.--first .p-whatis-point__catch::after {
  content: "";
  flex: 1;
  height: 1px;
  border-top: 5px dotted #D4000E;
  margin: 5.5rem 1rem 0; }

.p-whatis-point.--first .p-whatis-point__catch > img {
  width: auto; }

@media (max-width: 768px) {
  .p-whatis-point.--first .p-whatis-point__image {
    margin-right: -1.5rem; } }

@media (max-width: 768px) {
  .p-whatis-point.--first .p-whatis-point__experts {
    margin-top: -1rem;
    padding: 1rem 0 .5rem; } }

.p-whatis-point.--second .p-whatis-point__inner.--secondray {
  flex-direction: row-reverse; }

.p-whatis-point.--second .p-whatis-point__sentence {
  left: auto;
  right: 0; }

.p-whatis-point.--second .p-whatis-point__sentence::before {
  content: url(/assets/image/food-tourism/content02/icon.svg); }

@media (max-width: 768px) {
  .p-whatis-point.--second .p-whatis-point__catch {
    margin-left: auto; } }

@media (max-width: 768px) {
  .p-whatis-point.--second .p-whatis-point__image {
    margin-left: -1.5rem; } }

.p-whatis-point.--second .p-whatis-point__image {
  left: auto;
  right: calc(28rem + 3rem); }

.p-whatis-point.--third .p-whatis-point__sentence::before {
  content: url(/assets/image/food-tourism/content03/icon.svg);
  top: 90%;
  left: 105%; }
  @media (max-width: 768px) {
    .p-whatis-point.--third .p-whatis-point__sentence::before {
      top: auto;
      left: auto;
      right: 0; } }

.p-whatis-point-expert {
  position: relative;
  cursor: pointer; }
  .p-whatis-point-expert__face {
    display: block;
    transition: .3s; }
    .p-whatis-point-expert:hover .p-whatis-point-expert__face {
      -webkit-transform: scale(1.2);
              transform: scale(1.2); }
  .p-whatis-point-expert__name {
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .p-whatis-point-expert__name::before {
    content: "";
    position: absolute;
    right: -22px;
    top: 0;
    z-index: -1;
    width: 52px;
    height: 35px;
    background: url(/assets/image/food-tourism/content01/expert-name-accent.svg) no-repeat center; }

.p-whatis-meister {
  padding: 4rem 0;
  background: url(/assets/image/food-tourism/content04/bg.jpg) repeat center;
  color: #fff; }
  .p-whatis-meister__heading {
    margin-bottom: 2rem;
    font-size: 1.75rem;
    text-align: center; }
    @media (max-width: 768px) {
      .p-whatis-meister__heading {
        font-size: 1.25rem; } }
  .p-whatis-meister__main {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 60rem;
    margin: 0 auto 4rem; }
    @media (max-width: 768px) {
      .p-whatis-meister__main {
        width: 100%;
        margin-bottom: 3rem; } }
  .p-whatis-meister__main img {
    width: 100%; }
  .p-whatis-meister__caption {
    width: 28rem;
    margin: -9rem 0 0 auto;
    line-height: 1.7;
    text-align: justify; }
    @media (max-width: 768px) {
      .p-whatis-meister__caption {
        width: 100%;
        margin: 2rem auto 0; } }
  .p-whatis-meister__btn {
    width: 36rem;
    margin: auto; }
    @media (max-width: 768px) {
      .p-whatis-meister__btn {
        width: 100%; } }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 1; } }

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 1; } }

.u-fill {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }
  .u-fill__fillcontent {
    flex: 1; }

/* IE11 */
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop, body {
    font-family: "メイリオ", Meiryo, sans-serif; }
  *::-ms-backdrop, .p-roadmap_map img {
    width: auto;
    max-width: 100%; }
  *::-ms-backdrop, .c-zoom img:hover {
    opacity: 0.5; }
  *::-ms-backdrop, .p-advantage__heading::after {
    content: "";
    display: block;
    width: 56px;
    height: 43px;
    background: url(/assets/image/advantage/arrow.svg) no-repeat center/cover; }
  *::-ms-backdrop, .u-fill__fillcontent {
    flex-basis: auto; }
  *::-ms-backdrop, #teaser .p-header h1 {
    height: 16rem;
    background: url(/assets/image/header/logo.svg) no-repeat center/contain; }
  *::-ms-backdrop, #teaser .p-header img {
    display: none; } }

/* Edge */
/*@supports (-ms-ime-align: auto) {
  
}*/
/* Firefox */
/*@-moz-document url-prefix() {

}*/
.swiper-button-prev, .swiper-button-next {
  width: 56px;
  height: 44px;
  background-size: auto;
  transition: .3s ease;
  -webkit-transform: scale(0.8);
          transform: scale(0.8); }

.swiper-button-prev:hover, .swiper-button-next:hover {
  -webkit-transform: scale(1);
          transform: scale(1); }

.swiper-button-prev {
  background-image: url(/assets/image/common/arrow-left.svg); }

.swiper-button-prev.is-white-arrow {
  background-image: url(/assets/image/common/arrow-left-white.svg); }

.swiper-button-next {
  background-image: url(/assets/image/common/arrow-right.svg); }

.swiper-button-next.is-white-arrow {
  background-image: url(/assets/image/common/arrow-right-white.svg); }

.remodal {
  width: 25rem;
  border-radius: .5rem;
  border-top: 2px solid #D4000E;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
  @media (max-width: 768px) {
    .remodal {
      width: 80%;
      max-width: 25rem; } }
  .remodal-close {
    left: auto;
    right: .5rem;
    top: .5rem; }
  .remodal-close::before {
    font-size: 40px;
    line-height: 40px; }
  .remodal__face {
    display: table;
    width: 100%;
    max-width: 12rem;
    margin: 0 auto 1rem;
    border: .5rem solid #eee;
    border-radius: 100vw;
    font-size: 0; }
  .remodal__face > img {
    width: 100%; }
  .remodal__name {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;
    color: #D4000E;
    font-size: 1rem; }
  .remodal__name > span {
    font-size: 1.5rem; }
  .remodal__caption {
    text-align: justify;
    line-height: 1.7; }

.baguetteBox-button {
  background-color: transparent !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  transition: .3s ease;
  -webkit-transform: scale(0.8);
          transform: scale(0.8); }

.baguetteBox-button:hover {
  -webkit-transform: scale(1);
          transform: scale(1); }

.baguetteBox-button#previous-button, .baguetteBox-button#next-button {
  width: 56px;
  height: 44px;
  opacity: .6; }

.baguetteBox-button#previous-button > svg,
.baguetteBox-button#next-button > svg {
  display: none; }

.baguetteBox-button#previous-button {
  background-image: url(/assets/image/common/arrow-left-white.svg); }

.baguetteBox-button#next-button {
  background-image: url(/assets/image/common/arrow-right-white.svg); }

#teaser {
  padding-bottom: 5rem;
  background: url(/assets/image/common/bg.jpg) repeat center; }
  @media (max-width: 768px) {
    #teaser {
      padding-bottom: 3rem; } }
  #teaser::before {
    content: "";
    display: block;
    width: 100%;
    height: .5rem;
    margin-bottom: 5rem;
    background: url(/assets/image/common/bg-red.jpg) repeat center; }
    @media (max-width: 768px) {
      #teaser::before {
        margin-bottom: 3rem; } }
  #teaser .p-header h1 {
    width: 25rem;
    margin: 0 auto 2rem; }
    @media (max-width: 768px) {
      #teaser .p-header h1 {
        width: calc(100% - 3rem);
        max-width: 25rem; } }
  #teaser .p-header img {
    width: 100%; }
  #teaser .notice {
    width: 70rem;
    margin: 0 auto 8rem;
    font-size: 1.5rem;
    text-align: center;
    font-weight: bold;
    line-height: 1.5; }
    @media (max-width: 768px) {
      #teaser .notice {
        width: calc(100% - 3rem);
        margin-bottom: 5rem;
        padding-bottom: 3rem; } }
  #teaser #footer {
    padding: 0;
    background: none; }
  #teaser .p-footer-offices {
    color: #231815; }
    @media (max-width: 768px) {
      #teaser .p-footer-offices {
        text-align: center; } }
  #teaser .p-footer-offices__box + .p-footer-offices__box {
    border-color: rgba(35, 24, 21, 0.3); }
  @media (max-width: 768px) {
    #teaser .p-footer-offices__supplement {
      margin-bottom: 1rem; } }
  #teaser .p-footer-offices__heading {
    font-size: 1rem !important; }
    @media (max-width: 768px) {
      #teaser .p-footer-offices__heading {
        text-align: center; } }
  #teaser .p-footer__small {
    color: #231815; }
    @media (max-width: 768px) {
      #teaser .p-footer__small {
        text-align: center; } }

.u-hide-pc {
  display: none; }
  @media (max-width: 768px) {
    .u-hide-pc {
      display: block; } }

@media (max-width: 768px) {
  .u-hide-sp {
    display: none; } }
